import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// IMAGES
import aboutUsBanner from "../../../parent-assets/images/about-us-banner.jpg";
import enrichmentLogo from "../../../parent-assets/images/enrichment-images/enrichment-logo.png";
import axios from "axios";
import { getEnrichmentManageProgramData } from "../../../../Admin/adminApi/manageProgramData";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer";

function PepPlus() {
  const [comboData, setComboData] = useState();
  const [showShimmer, setShowShimmer] = useState();
  const [showMore, setShowMore] = useState([]);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  let dummyData = state;
  const [enrichmentPepData, setEnrichmentPepData] = useState([]);

  const moreInfoHandler = (data, item) => {
    navigate(`/browse-by-program/${state?.programId}/${id}?browse-by=true`, {
      state: {
        comboData: item,
        mainData: dummyData.enrichmentClasses,
        isEnrichmentCombo: true,
      },
    });
  };

  useEffect(() => {
    getEnrichmentPageHandler();
    getEnrichmentHeaderImage();
  }, []);
  const getEnrichmentHeaderImage = async () => {
    try {
      setShowShimmer(true);
      const response = await getEnrichmentManageProgramData(
        token,
        state?.programId
      );
      const pepData = response?.data?.data?.filter((data) => data?.id == id);
      setComboData(pepData);
      setShowShimmer(false);
    } catch (error) {
      console.error("Error while genereting header image");
    }
  };
  const getEnrichmentPageHandler = async () => {
    const postData = {
      categoryId: `${id}`,
    };

    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/getClassesCMSAdminForCombo`,
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setEnrichmentPepData(response?.data?.data);
    setShowMore(new Array(response?.data?.data?.length)?.fill(false));
  };
  const expandHandler = (index) => {
    setShowMore((prevState) => {
      const temp = [...prevState];
      temp[index] = !temp[index];
      return temp;
    });
  };
  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Birthday"} />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#C3D940" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    comboData?.[0]?.header_image
                      ? comboData?.[0]?.header_image
                      : aboutUsBanner
                  }
                  alt="about-us-banner"
                />
              </div>
            </section>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="facilitiesSec">
            <div className="container">
              <div
                className="picture enr_icon"
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <img
                  src={enrichmentLogo}
                  alt="gymtimeGuy"
                  className="picture__img enr_icon"
                />
                <h1 className="title title--withicon title--extrabold girls_gymnastics_title_new">
                  {id === "45" ? "Recreational Cheer" : `${state?.programName}`}{" "}
                </h1>
              </div>

              {enrichmentPepData.length > 0 ? (
                enrichmentPepData?.map((item, index) => (
                  <div className="programList">
                    <div className="programList__item">
                      <div className="programList__card">
                        <div class="picture">
                          <img
                            class="picture__img"
                            src={
                              item?.image
                                ? item?.image
                                : "https://cdn.gymtime.net/image1706098488218.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="detail">
                          <div className="content">
                            <h3 className="title title--extrabold">
                              {item?.umbrella_name
                                ? item?.umbrella_name
                                : item.name}
                            </h3>
                            <h4 className="timeperiod">
                              {item?.min_age > 23
                                ? `${Math.floor(item?.min_age / 12)} ${
                                    item?.min_age % 12 ? "." : ""
                                  } ${
                                    item?.min_age % 12 ? item?.min_age % 12 : ""
                                  }Years`
                                : `${item?.min_age} Months`}
                              -
                              {item?.max_age > 23
                                ? `${Math.floor(item?.max_age / 12)} ${
                                    item?.max_age % 12 ? "." : ""
                                  } ${
                                    item?.max_age % 12 ? item?.max_age % 12 : ""
                                  }Years`
                                : `${item?.max_age} Months`}
                            </h4>
                            <p className="para">
                              {item?.description ? (
                                <>
                                  {showMore?.[index]
                                    ? item?.description
                                    : item?.description?.slice(0, 200)}
                                  <button
                                    style={{ color: "red" }}
                                    onClick={() => expandHandler(index)}
                                  >
                                    {showMore?.[index]
                                      ? "...show less"
                                      : "...show more"}
                                  </button>
                                </>
                              ) : (
                                `With over 30 years of experience, we've established ourselves as the leader in child development.`
                              )}
                            </p>
                          </div>
                          <div className="buttons">
                            <button
                              className="btn btn--primary"
                              onClick={() => {
                                moreInfoHandler(item?.id, item);
                              }}
                            >
                              More Info
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ padding: "20px 70px" }}>
                  <div
                    className="title title--h1 text-center"
                    style={{ color: "#EA519C", paddingTop: "20px" }}
                  >
                    No Programs available.
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default PepPlus;
