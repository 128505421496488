import React, { useEffect, useState } from "react";
import {
  createMakeUpClass,
  getMakeUpModalkDetailsAPI,
} from "../../adminApi/makeUpClassApi";
import "./makeup.css";
import {
  convertDateFormat,
  customStyles,
  dateFormatter,
  fetchSortedClass,
  makeupClassOption,
  timeFormatter,
} from "../../adminUtility";
import Select from "react-select";

import LoaderComponent from "../../components/LoaderComponent";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import { getClassesForMakeUp } from "../../adminApi/classes";
import { ReactComponent as SortIcon } from "../../assets/images/icons/sort.svg";
import ReactPaginate from "react-paginate";
import SelectedMakeupComponent from "./SelectedMakeupComponent";

function AddNewMakeUpClass({
  setOpenMakeUpClass,
  openMakeUpClass,
  selectedClassId,
  selectedStudentID,
}) {
  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const [makeUpData, setMakeUpData] = useState();
  const [makeupApiData, setMakeupApiData] = useState(makeUpData);
  const [makeupForm, setMakeUpFormData] = useState({
    selectedDate: formatDate(new Date()),
    selectedClass: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState("20");
  const [classListData, setClassList] = useState();
  const [selectedClassID, setSelectedClassId] = useState();
  const [sortKey, setSortKey] = useState({
    sort: "0",
    isAsc: true,
  });
  const [selectedClass, setSelectedClass] = useState(null);
  // Payload For Adding makeup/trial/dropdin
  const [makeUpDetails, setMakeUpDetails] = useState([]);
  const [trialDetails, setTrialDetails] = useState([]);
  const [dropInDetails, setDropInDetails] = useState([]);
  const [currentSelections, setCurrentSelections] = useState({});
  const [memos, setMemos] = useState({});
  const [selectedMakeupData, setSelectedMakeupData] = useState([]);
  const [selectionsByClass, setSelectionsByClass] = useState(new Map());

  const handleRowChange = (e) => {
    setSelectedRow(e.target.value);
  };

  const handleMakeupOptionSelect = (index, selection, memo) => {
    const { date, classScheduleDetailId, option, val } = selection;
    const newEntry = {
      date,
      classScheduleDetailId,
      memo,
      type: option,
      val: val,
    };

    const removeFromList = (list, entry) => {
      const index = list.findIndex(
        (item) =>
          item.date === entry.date &&
          item.classScheduleDetailId === entry.classScheduleDetailId
      );
      if (index > -1) {
        const newList = [...list];
        newList.splice(index, 1);
        return newList;
      }
      return list;
    };

    // Update the state based on previous selection
    const previousOption = currentSelections[index];
    if (previousOption) {
      if (previousOption === "makeup") {
        setMakeUpDetails((prev) => removeFromList(prev, newEntry));
      } else if (previousOption === "trial") {
        setTrialDetails((prev) => removeFromList(prev, newEntry));
      } else if (previousOption === "dropIn") {
        setDropInDetails((prev) => removeFromList(prev, newEntry));
      }
    }

    // Add to the respective list based on the new selection
    if (option === "makeup") {
      setMakeUpDetails((prev) => [...prev, newEntry]);
    } else if (option === "trial") {
      setTrialDetails((prev) => [...prev, newEntry]);
    } else if (option === "dropIn") {
      setDropInDetails((prev) => [...prev, newEntry]);
    }

    setCurrentSelections((prev) => ({
      ...prev,
      [index]: option,
    }));

    setMemos((prev) => ({
      ...prev,
      [index]: memo,
    }));
  };

  const handleDateChange = (date) => {
    if (selectedClass !== null) {
      setSelectedClass(null);
    }
    setMakeUpFormData({ ...makeupForm, selectedDate: formatDate(date) });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const payloadData = {
      makeUpDetails,
      trialDetails,
      dropInDetails,
      studentId: selectedStudentID?.id,
    };
    setLoading(true);
    try {
      const response = await createMakeUpClass(payloadData);
      if (response == undefined) {
        setLoading(false);
      }
      if (response?.status == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        toast.success(response?.data?.data);
        setOpenMakeUpClass(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getClassesForMakeUpAPI = async () => {
    setLoading(true);
    try {
      const response = await getClassesForMakeUp(
        makeupForm?.selectedDate,
        sortKey,
        token,
        currentPage,
        selectedRow,
        selectedClass
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setMakeupApiData(response?.data.getClassesForMakeUp);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchHandler = async () => {
    getClassesForMakeUpAPI();
  };

  useEffect(() => {
    setCurrentSelections({});
    setMemos({});
  }, [selectedClassID]);

  useEffect(() => {
    getClassesForMakeUpAPI();
  }, [
    makeupForm?.selectedDate,
    sortKey,
    currentPage,
    selectedRow,
    selectedClass?.label,
  ]);

  useEffect(() => {
    const data = {
      date: makeupForm?.selectedDate,
      classId: selectedClassID,
    };

    // For Selecting Trial & makeup/DropdInd
    const classSearched = async () => {
      setLoading(true);
      try {
        const response = await getMakeUpModalkDetailsAPI(data, token);
        if (response?.data?.length) {
          setLoading(false);
        } else {
          setLoading(false);
        }
        setMakeUpData(response?.data);
      } catch (error) {
        setLoading(false);
      }
    };
    if (selectedClassID !== undefined || null || "Select Classs" && data?.classId ) {
      classSearched();
    }
  }, [selectedClassID]);

  // CLASS SEARCHING
  useEffect(() => {
    fetchSortedClass(setLoading, setClassList);
  }, []);

  const handleClassChange = (selectedOption) => {
    const newClassID = selectedOption?.label;
    if (selectedClassID) {
      setSelectionsByClass((prev) => ({
        ...prev,
        [selectedClassID]: {
          makeUpDetails,
          trialDetails,
          dropInDetails,
          currentSelections,
          memos,
        },
      }));
    }

    // Load selections for the new class ID
    const existingData = selectionsByClass[newClassID] || {
      makeUpDetails: [],
      trialDetails: [],
      dropInDetails: [],
      currentSelections: {},
      memos: {},
    };

    setSelectedClass(selectedOption);
    setSelectedClassId(selectedOption?.value);
    setMakeUpDetails(existingData.makeUpDetails);
    setTrialDetails(existingData.trialDetails);
    setDropInDetails(existingData.dropInDetails);
    setCurrentSelections(existingData.currentSelections);
    setMemos(existingData.memos);
  };

  const handleDelete = (item) => {
    const removeFromList = (list, entry) => {
      const index = list.findIndex(
        (i) =>
          i.date === entry.date &&
          i.classScheduleDetailId === entry.classScheduleDetailId
      );
      if (index > -1) {
        const newList = [...list];
        newList.splice(index, 1);
        return newList;
      }
      return list;
    };

    if (item.type === "makeup") {
      setMakeUpDetails((prev) => removeFromList(prev, item));
    } else if (item.type === "trial") {
      setTrialDetails((prev) => removeFromList(prev, item));
    } else if (item.type === "dropIn") {
      setDropInDetails((prev) => removeFromList(prev, item));
    }
  };

  useEffect(() => {
    setSelectedMakeupData([
      ...makeUpDetails,
      ...trialDetails,
      ...dropInDetails,
    ]);
  }, [makeUpDetails, trialDetails, dropInDetails]);
  return (
    <>
      {loading && <LoaderComponent />}
      <div
        className={`Admin-modal ${openMakeUpClass ? "Admin-modal--open" : ""}`}
        id="modalAddNewClass"
        role="dialog"
      >
        <div class="Admin-modal__dialog Admin-modal__dialog--lg">
          <div
            class="Admin-modal__content"
            style={{
              margin: "0px 10px",
              width: "95vw",
              height: "90vh",
              minHeight: "90vh",
            }}
          >
            <div class="Admin-modal__head" style={{ borderRadius: "10px" }}>
              <h2 class="Admin-modal__head--title">+ Available Makeup Slots</h2>
              <button
                class="Admin-modal__close"
                onClick={() => {
                  setOpenMakeUpClass(false);
                }}
              >
                <CloseIcon />
              </button>
            </div>

            <div
              class="Admin-modal__body makeupBody"
              style={{ height: "100%" }}
            >
              <div class="Admin-modal__bodytab ">
                <div
                  className=""
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div className="column">
                    <label htmlFor="">Select Date(MM/DD/YYYY)</label>

                    <DatePicker
                      name="selectedDate"
                      className="adminform__input"
                      selected={new Date(makeupForm?.selectedDate)}
                      onChange={handleDateChange}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select Makeup Date"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  <div className="column" style={{ maxWidth: "300px" }}>
                    <label htmlFor="">Select Class</label>

                    <Select
                      styles={customStyles}
                      value={selectedClass}
                      onChange={handleClassChange}
                      options={makeupClassOption(classListData)}
                    />
                  </div>
                  <div className="" style={{ marginTop: "20px" }}>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={searchHandler}
                    >
                      Search
                    </button>
                  </div>
                </div>

                {makeupApiData?.data?.length > 0 && (
                  <p className="label" style={{ color: "red" }}>
                    *Select Class For Makeup{" "}
                  </p>
                )}

                {/* SELECTED MAKEUP CARD DATAA  */}
                <div className="makeupContainer">
                  {selectedMakeupData?.map((val, index) => {
                    return (
                      <SelectedMakeupComponent
                        key={index}
                        selectedData={val}
                        handleDelete={() => handleDelete(val)}
                      />
                    );
                  })}
                </div>

                {/* TABLE  */}

                <div className="flexbox">
                  {/* SEARCHED BY DATE  */}

                  {makeupApiData?.data?.length > 0 && (
                    <div
                      className="column"
                      style={{ height: "50vh", overflowY: "scroll" }}
                    >
                      <div
                        className="Table"
                        style={{ minHeight: "40vh", overflowY: "scroll" }}
                      >
                        <div className="Heading">
                          <div
                            className="Cell  Heading_style"
                            style={{ width: "65px" }}
                            onClick={() => {
                              setSortKey((prevSortKey) => ({
                                sort: 0,
                                isAsc: !prevSortKey.isAsc,
                              }));
                            }}
                          >
                            <p>
                              {" "}
                              <SortIcon /> &nbsp; ID
                            </p>
                          </div>
                          <div
                            className="Cell  Heading_style"
                            style={{ width: "180px" }}
                            onClick={() => {
                              setSortKey((prevSortKey) => ({
                                sort: 1,
                                isAsc: !prevSortKey.isAsc,
                              }));
                            }}
                          >
                            <p>
                              {" "}
                              <SortIcon /> &nbsp;Class Name
                            </p>
                          </div>
                          <div
                            className="Cell  Heading_style"
                            style={{ width: "120px" }}
                          >
                            <p> &nbsp;Teacher</p>
                          </div>
                          <div
                            style={{ width: "120px" }}
                            className="Cell  Heading_style"
                          >
                            <p>&nbsp;Schedule</p>
                          </div>
                          <div
                            className="Cell  Heading_style"
                            onClick={() => {
                              setSortKey((prevSortKey) => ({
                                sort: 4,
                                isAsc: !prevSortKey.isAsc,
                              }));
                            }}
                          >
                            <p>
                              {" "}
                              <SortIcon /> &nbsp;Min Age
                            </p>
                          </div>
                          <div
                            className="Cell  Heading_style"
                            onClick={() => {
                              setSortKey((prevSortKey) => ({
                                sort: 5,
                                isAsc: !prevSortKey.isAsc,
                              }));
                            }}
                          >
                            <p>
                              {" "}
                              <SortIcon /> &nbsp;Max Age
                            </p>
                          </div>
                        </div>

                        {makeupApiData?.data?.map((val, index) => {
                          return (
                            <div
                              key={val.id}
                              className="Row"
                              style={{
                                cursor: "pointer",
                                background:
                                  val?.id == selectedClassID
                                    ? "#5497C7"
                                    : index % 2 === 0
                                    ? "rgba(134, 134, 134, 0.1490196078)"
                                    : "white",
                                color:
                                  val?.id == selectedClassID
                                    ? "white"
                                    : "black",
                                border: "1px solid black",
                              }}
                              onClick={() => {
                                setSelectedClassId(val?.id);
                              }}
                            >
                              <div className="Cell">
                                <p>{val?.id}</p>
                              </div>
                              <div className="Cell">
                                <p>{val?.name ? val?.name : "-"}</p>
                                <p>
                                  {dateFormatter(val?.start_date)} <br />
                                  to <br />
                                  {dateFormatter(val?.end_date)}
                                </p>
                              </div>
                              <div className="Cell">
                                {val?.schedules?.map((teacher) => {
                                  return (
                                    <p>
                                      {teacher?.name !== null
                                        ? teacher?.name
                                        : "-"}
                                    </p>
                                  );
                                })}
                              </div>
                              <div className="Cell">
                                {val?.schedules?.map((teacher) => {
                                  return (
                                    <p>
                                      {teacher?.weekday !== null
                                        ? teacher?.weekday
                                        : "-"}{" "}
                                      <br />
                                      {timeFormatter(
                                        teacher?.start_time
                                      )} - {timeFormatter(teacher?.end_time)}
                                    </p>
                                  );
                                })}
                              </div>
                              <div className="Cell">
                                <p>{val?.min_age}</p>
                              </div>
                              <div className="Cell">
                                <p>{val?.max_age}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="flexbox"
                        style={{ alignItems: "center ", width: "100%" }}
                      >
                        <div className="column" style={{ width: "60%" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={makeupApiData?.totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                          />
                        </div>

                        <div className="column" style={{ width: "10%" }}>
                          <label htmlFor="" className="label">
                            Number of Rows to Show
                          </label>
                          <select
                            style={{ width: "100px" }}
                            name="limit"
                            id="limit"
                            className="adminform__select"
                            value={selectedRow}
                            onChange={handleRowChange}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedClass !== undefined &&
                    makeupApiData?.data?.length == 0 && (
                      <div className="column">
                        <p
                          style={{
                            color: "red",
                            marginTop: "5%",
                            marginLeft: "10%",
                          }}
                        >
                          No Data Found
                        </p>
                      </div>
                    )}

                  {/* FINAL SEARCH TABLE DATA  */}
                  <div className="column">
                    {makeUpData?.length > 0 &&
                    makeupApiData?.data?.length > 0 ? (
                      <div
                        className="adminform__group"
                        style={{ height: "49vh", overflowY: "scroll" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ color: "white", width: "70%" }}>
                                Class Date
                              </th>
                              <th style={{ color: "white", width: "30%" }}>
                                Memo
                              </th>
                              <th style={{ color: "white", width: "30%" }}>
                                Max Strength
                              </th>
                              <th style={{ color: "white", width: "30%" }}>
                                Makeup Options
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {makeUpData?.map((val, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ maxWidth: "4%", }}>
                                    {val?.date}
                                    <br />({" "}
                                    {val?.slotsLeft ? val?.slotsLeft : ""} Slots
                                    Available)
                                  </td>
                                  <td>
                                    <input
                                      className="adminform__input"
                                      type="text"
                                      placeholder="Memo"
                                      value={memos[index] || ""}
                                      onChange={(e) => {
                                        setMemos((prev) => ({
                                          ...prev,
                                          [index]: e.target.value,
                                        }));
                                        handleMakeupOptionSelect(
                                          index,
                                          {
                                            date: convertDateFormat(val?.date),
                                            classScheduleDetailId:
                                              val?.classScheduleDetailId,
                                            option: currentSelections[index],
                                            val: val,
                                          },
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </td>
                                  <td style={{maxWidth:"20%"}}>{val?.classMaxStrength}</td>
                                  <td>
                                    <select
                                      name=""
                                      id=""
                                      className="adminform__select"
                                      onChange={(e) =>
                                        handleMakeupOptionSelect(
                                          index,
                                          {
                                            date: convertDateFormat(val?.date),
                                            classScheduleDetailId:
                                              val?.classScheduleDetailId,
                                            option: e.target.value,
                                            val: val,
                                          },
                                          memos[index]
                                        )
                                      }
                                      value={currentSelections[index] || ""}
                                    >
                                      <option value="">
                                        Select MakeUp Options
                                      </option>
                                      <option value="makeup">Makeup</option>
                                      <option value="trial">Trial</option>
                                      <option value="dropIn">DropIn</option>
                                    </select>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center", margin: "7% 0%" }}>
                        {" "}
                        <h3 className="title--h4">No Data Found!</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div class="Admin-modal__footer">
                <div
                  class="admin-button__group"
                  style={{
                    display: "flex",
                    alignItems: "right",
                    marginLeft: "75%",
                    gap: "20px",
                  }}
                >
                  <button
                    class="adminbtn adminbtn-blueOutline"
                    onClick={() => {
                      setOpenMakeUpClass(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="adminbtn adminbtn-primary"
                    onClick={submitHandler}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewMakeUpClass;
