import React, { useEffect, useState } from "react";
import { aboutPageContent } from "../../api/contentApi";
import { Link, useNavigate } from "react-router-dom";
import popUp1 from "../../parent-assets/images/Pop_Images/pop-up-1.jpg";
import popUp2 from "../../parent-assets/images/Pop_Images/pop-up-2.jpg";
import popUp3 from "../../parent-assets/images/Pop_Images/pop-up-3.jpg";
import { popUpsGetAll } from "../../../Admin/adminApi/popUps";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";
const PopUp = () => {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const [popUpsData, setPopUpsData] = useState();
  const [popUpHeader, setPopUpHeader] = useState();
  const [showShimmerHeader, setShowShimmerHeader] = useState(false);
  
  useEffect(() => {
    getAllPopUpsData();
    getPopUpHeaderImage();
  }, [])
  const getPopUpHeaderImage = async () => {
    try {
      setShowShimmerHeader(true);
      const section = await getSectionCMS(token, 182);
      setPopUpHeader(section)
      setShowShimmerHeader(false);
    } catch (error) {
      setShowShimmerHeader(false);
    }
  }
  const getAllPopUpsData = async () => {
    try {
      const response = await popUpsGetAll();
      setPopUpsData(response?.data?.data)
    } catch (error) {
    }
  }
  return (
    <>


      <div className="contentwraper">
        {
          showShimmerHeader ? <AboutUsShimmer component={"Birthday"}/> :

            <section style={{ position: "relative" }}>
              <section className="siteBanner" style={{ background: "#ea519c" }}>
                <div className="siteBanner__overlay">

                  <img
                    className="siteBanner__img"
                    src={popUpHeader?.[1]?.imageSection?.image ? popUpHeader?.[1]?.imageSection?.image : aboutPageContent.aboutBannerImage.url}
                    alt="banner"
                  />

                </div>
              </section>
            </section>
        }

        <section className="breadcrumbSec">
          <div className="container">
            <div className="breadcrumb">
              <div className="breadcrumb__item">
                <Link
                  className="breadcrumb__link"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back to results
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutusSec">
          <div className="container">
      
          {    showShimmerHeader ?  "":
              <div className="aboutData">
                <div
                  className="popup_title_container"
                  style={{ marginBottom: "10px" }}
                >
                  <h1 className="title title--extrabold">POP UPS!</h1>
                </div>
                <div className="popUp_shortText">
                  <p className="para">
                    {popUpHeader?.[0]?.descriptionSection?.description}
                  </p>
                </div>

                <div className="parent_pop_caontainer_fluid">
                  <div className="pop_up_body_container_fluid">

                    <div className="pop_up_body_box_row">
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-1">
                          <img src={popUpsData?.[0]?.image} alt="" />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {
                              popUpsData?.[0]?.description
                            }
                          </p>
                        </div>
                      </div>
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-2">
                          <img src={popUpsData?.[1]?.image} alt="" style={{ margin: "43px" }} />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {popUpsData?.[1]?.description}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="pop_up_body_box_row">
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-3">
                          <img src={popUpsData?.[2]?.image} alt="" style={{ margin: "43px" }} />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {popUpsData?.[2]?.description}
                          </p>
                        </div>
                      </div>
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-4">
                          <img src={popUpsData?.[3]?.image} alt=""  />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {popUpsData?.[3]?.description}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="pop_up_body_box_row">
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-5">
                          <img src={popUpsData?.[4]?.image} alt="" style={{ margin: "38px" }} />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {popUpsData?.[4]?.description}
                          </p>
                        </div>
                      </div>
                      <div className="pop_up_box_col">
                        <div className="pop_up_image_container-6">
                          <img src={popUpsData?.[5]?.image} alt="" />
                        </div>
                        <div className="pop_col_paratext_container">
                          <p className="pop_col_paratext">
                            {popUpsData?.[5]?.description}
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

    </>
  );
};

export default PopUp;
