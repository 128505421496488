import React from "react";
import { useEffect } from "react";
import { setLoading } from "../../../../reducers/loadingSlice";
import { toast } from "react-toastify";
import { setSuccessModalContent } from "../../../../reducers/userSlice";
import {
  deleteStudent,
  getAllStudent,
  updateStudentAPI,
} from "../../../api/studentApi";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidName, isValidUSZipCode } from "../../../utility/validation";
import { getStates } from "../../../../Admin/adminApi/users";
import { differenceInMonths, parse } from "date-fns";
import DatePicker from "react-datepicker";
import { AgeCalculationFunction } from "../../../utility/postRequests";
import LoaderComponent from "../../../../Admin/components/LoaderComponent";
import { format, parseISO } from "date-fns";

function StudentUpdate({ setSuccessModal, temp, activeTab }) {
  // Parent and Student Account..
  const [getAllStudentState, setGetAllStudentState] = useState();
  const [ageInMonths, setAgeInMonths] = useState();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state);

  // FORM LOGICS AND API
  // STUDENT PROFILE FORMS -------------------------->
  const [studentFormData, setStudentFormData] = useState([
    {
      firstName: "",
      lastName: "",
      dob: "",
      gender: "Male",
      allergies: "",
      address: "",
      age: "",
      doctor_name: "",
      doctor_phone: "",
      parent_signature: "",
      emergency_phone: "",
      emergency_name: "",
      vaccination: "YES",
      photo_release: "",
      school_attending_withs: [{ name: "", attending_with: "" }],
      image: "",
    },
  ]);
  // age calucaltor
  const [totalAge, setTotalAge] = useState();
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);

    let ageInYears = today.getFullYear() - birthDate.getFullYear();
    let monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      ageInYears--;
      monthDiff += 12;
    }

    if (ageInYears >= 2) {
      setTotalAge(`${ageInYears} years`);
      return { CalculatedValue: ageInYears, key: "years" };
    } else {
      setTotalAge(`${monthDiff} months`);
      return { CalculatedValue: monthDiff, key: "months" };
    }

    // setMonth(monthDiff);
  }
  // ERROR HANDLING ..
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    allergies: "",
    address: "",
    age: "",
    doctor_name: "",
    doctor_phone: "",
    parent_signature: "",
    emergency_phone: "",
    emergency_name: "",
    vaccination: "",
    photo_release: "",
    state: "US",
    zipcode: "",
    school_attending_withs: [{ name: "", attending_with: "" }],
  });
  const [getAllStateApi, setGetAllStateApi] = useState();

  const addAttendingWith = (e, index) => {
    const newAttendingWith = { attending_with: "", name: "" };
    const resultant = getAllStudentState.map((x, i) => {
      if (index === i) {
        x?.school_attending_withs.push(newAttendingWith);
        return x;
      } else {
        return x;
      }
    });
    setGetAllStudentState(resultant);
  };

  const handleArrayChange = (e, index, i) => {
    e.preventDefault();
    const { name, value } = e?.target;
    const resultant = getAllStudentState.map((x, ind) => {
      if (index === ind) {
        const updatedSchoolAttending = [...x.school_attending_withs];
        updatedSchoolAttending[i][name] = value;
        x.school_attending_withs = updatedSchoolAttending;
        return x;
      } else {
        return x;
      }
    });

    setGetAllStudentState(resultant);
    if (value === studentFormData?.emergency_name) {
      const updatedSchoolAttending = [...errors.school_attending_withs];
      updatedSchoolAttending[index][name] = "Do not same with emergency name";

      setErrors({
        ...errors,
        school_attending_withs: updatedSchoolAttending,
      });
    } else {
      const updatedSchoolAttending = [...errors.school_attending_withs];
      updatedSchoolAttending[index][name] = "";
      setErrors({
        ...errors,
        school_attending_withs: updatedSchoolAttending,
      });
    }
  };
  // UPDATE API SUBMIT HANDLER
  const updateStudent = async (e, studentID, index) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (!hasErrors) {
      toast.error("Please fill the details correctly.");
      return;
    }
    const token = localStorage.getItem("access_token");

    const tempData = getAllStudentState[index];

    const school_attending_withs = tempData.school_attending_withs;
    delete tempData.school_attending_withs;
    delete tempData.fk_clients_students;

    const temp = {
      studentDetails: tempData,
      schoolAttendingWithDetails: school_attending_withs,
    };

    dispatch(setLoading(true));
    try {
      const response = await updateStudentAPI(temp, studentID, token);
      if (response?.data?.data == null || undefined) {
        dispatch(setLoading(false));
        toast.error(response?.data?.error);
        return;
      } else {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 500);
        setTimeout(() => {
          setSuccessModal(true);
          dispatch(setSuccessModalContent("Student Updated Succesfully."));
        }, 500);
        getAllStudntData(token);
      }
    } catch (error) {
      toast.error("Failed to update student. Please try again.");
    }
  };

  const deleteStudentHandle = async (deleteId) => {
    const token = localStorage.getItem("access_token");

    dispatch(setLoading(true));
    try {
      const response = await deleteStudent(deleteId, token);
      dispatch(setSuccessModalContent("Student Deleted Successfully"));
      setSuccessModal(true);
      setGetAllStudentState((prevStudentData) =>
        prevStudentData.filter((student) => student.id !== deleteId)
      );
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    } finally {
      dispatch(setLoading(false));
    }
    getAllStudntData();
  };

  const getAllStudntData = async () => {
    let token = localStorage.getItem("access_token");
    const allStudentResponse = await getAllStudent(token);
    setGetAllStudentState(allStudentResponse?.getStudents);
  };

  const handleFileChange = (e, index) => {
    e.preventDefault();
    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const updatedStudent = [...getAllStudentState];
          updatedStudent[index][e.target.name] = reader.result;
          setGetAllStudentState(updatedStudent);
        };
        reader.readAsDataURL(file);
      } else {
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (activeTab == "tab2") {
      getAllStudntData();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab == "tab2") {
      getAllStudntData();
    }
  }, [temp, activeTab]);

  const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form__input"
      placeholder="Select dob"
      value={value}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      ref={ref}
    />
  ));

  const handleEditChange = (e, index) => {
    const updatedStudentData = [...getAllStudentState];
    let { name, value } = e.target;
    if (e.target.type === "radio") {
      value = e.target.value === "Yes";
    }

    const enteredDate = parse(value, "yyyy-MM-dd", new Date());
    const monthsDifference = differenceInMonths(new Date(), enteredDate);
    setAgeInMonths(monthsDifference);

    if (name === "dob") {
      updatedStudentData[index]["age"] = monthsDifference;
    }

    switch (name) {
      case "dob":
        handleDateChange(new Date(value));
        break;

      case "doctor_name":
        if (!isValidName(e.target.value)) {
          setErrors({
            ...errors,
            [name]: "Doctor's name doesn't contains number  .",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "zipcode":
        if (!isValidUSZipCode(e.target.value)) {
          setErrors({ ...errors, [name]: "Zip code must be 5-digit. " });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "first_name":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "First name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "emergency_name":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "Emergency name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      case "last_name":
        if (!isValidName(value)) {
          setErrors({
            ...errors,
            [name]: "Last name does not contain numbers",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "photo_release":
        if (!value) {
          setErrors({
            ...errors,
            [name]: "Please select an option for photo release.",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;
      case "parent_signature":
        if (!value) {
          setErrors({
            ...errors,
            [name]: "Please enter the signature.",
          });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        break;

      default:
        setErrors({ ...errors, [name]: "" });
    }

    updatedStudentData[index][name] = value;
    setGetAllStudentState(updatedStudentData);
  };

  const handleDateChange = (date, index) => {
    const updatedStudentData = [...getAllStudentState];

    // Ensure the date is a Date object
    const enteredDate = date instanceof Date ? date : parseISO(date);

    const monthsDifference = differenceInMonths(new Date(), enteredDate);
    setAgeInMonths(monthsDifference);

    const { CalculatedValue, key } = calculateAge(enteredDate);

    updatedStudentData[index]["dob"] = enteredDate.toISOString().split("T")[0];
    updatedStudentData[index]["age"] = `${CalculatedValue} ${key}`;

    // Assuming setAllStudentState is a function to update state
    // setAllStudentState(updatedStudentData);
  };
  // all States
  useEffect(() => {
    async function getAllStates() {
      const response = await getStates();
      setGetAllStateApi(response?.data?.getStates);
    }
    if (activeTab == "tab2") {
      getAllStates();
    }
  }, [activeTab]);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = parseISO(dateString);
    return format(parsedDate, "MM/dd/yyyy");
  };

  // Helper function to parse the selected date back to yyyy/mm/dd format
  const parseDate = (date) => {
    if (!date) return null;
    return format(date, "yyyy-MM-dd");
  };

  return (
    <>
      {loader?.loading?.loading && <LoaderComponent />}

      {getAllStudentState?.map((student, index) => {
        return (
          <form
            className="form parentForm2"
            style={{ marginTop: "5%" }}
            onSubmit={(e) => {
              updateStudent(e, student?.id, index);
            }}
            key={index}
          >
            <div className="form__card">
              <div className="form__header">
                <h4 className="title title--h4" style={{ background: "none" }}>
                  Student {index + 1}
                </h4>
              </div>

              <div className="profileBox">
                <div className="profileBox__info">
                  <div className="pic">
                    <img className="pic__img" src={student?.image} alt="" />
                  </div>
                  <div className="detail">
                    <h3 className="name">
                      {student?.first_name} &nbsp;{student?.last_name}
                    </h3>
                    <p className="para">
                      {AgeCalculationFunction(student?.dob)}
                    </p>
                  </div>
                </div>
                <div className="profileBox__actions">
                  <div
                    className="profileBox__actions__item"
                    style={{ display: "flex" }}
                  >
                    <input
                      className="form__input form__input--hidden"
                      type="file"
                      id={`fileupload-${index + 1}`}
                      name="image"
                      placeholder="upload image"
                      onChange={(e) => {
                        handleFileChange(e, index);
                      }}
                    />
                    <label
                      for={`fileupload-${index + 1}`}
                      className="btn btn--primary"
                    >
                      Upload New Photo
                    </label>
                  </div>
                  {/* <div className="profileBox__actions__item">
                    <button
                      className="btn btn--primary btn--primary--outline"
                      type="button"
                      onClick={() => {
                        deleteStudentHandle(student.id);
                      }}
                    >
                      Delete
                    </button>
                  </div> */}
                </div>
              </div>
              {/* EDITING FORM . */}

              <div className="form__group form__group--flex">
                <div className="form__group__col">
                  <label htmlFor="">
                    First Name <span className="important_field">*</span>
                  </label>
                  <input
                    className="form__input"
                    id="Name"
                    type="text"
                    placeholder="First name *"
                    name="first_name"
                    value={student.first_name}
                    onChange={(e) => handleEditChange(e, index)}
                  />
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.first_name}
                  </div>
                </div>

                <div className="form__group__col">
                  <label htmlFor="">
                    Last Name <span className="important_field">*</span>
                  </label>
                  <input
                    className="form__input"
                    id="Lastname"
                    type="text"
                    placeholder="Last name *"
                    name="last_name"
                    value={student.last_name}
                    onChange={(e) => handleEditChange(e, index)}
                  />
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.last_name}
                  </div>
                </div>
              </div>

              <div className="form__group form__group--flex">
                <div className="form__group__col">
                  <label htmlFor="">
                    DOB <span className="important_field">*</span>
                  </label>
                  <DatePicker
                    className="form__input"
                    id="Date"
                    selected={student?.dob ? parseISO(student.dob) : null}
                    onChange={(date) =>
                      handleDateChange(parseDate(date), index)
                    }
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<CustomDatePickerInput />}
                  />
                  <div className="error-message" style={{ color: "red" }}>
                    {errors.dob}
                  </div>
                </div>
                <div className="form__group__col">
                  <label htmlFor="">
                    Gender <span className="important_field">*</span>
                  </label>
                  <select
                    id="Gender"
                    className="form__select"
                    name="gender"
                    value={student.gender}
                    onChange={(e) => handleEditChange(e, index)}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="form__group">
                <label htmlFor="">
                  Age<span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Na"
                  type="text"
                  disabled="true"
                  placeholder="Age *"
                  name="age"
                  // value={`${student.age}`}
                  value={AgeCalculationFunction(student?.dob)}
                  onChange={(e) => handleEditChange(e, index)}
                />

                <div className="error-message" style={{ color: "red" }}>
                  {errors.age}
                </div>
              </div>
              <div className="form__group">
                <label htmlFor="">
                  Allergies <span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Na"
                  type="text"
                  placeholder="Known allergies/physical limitations/OT/PT (if none, type N/A) *"
                  name="allergies"
                  value={student.allergies}
                  onChange={(e) => handleEditChange(e, index)}
                />
                <div className="error-message" style={{ color: "red" }}>
                  {errors.allergies}
                </div>
              </div>
              <div className="form__group">
                <label htmlFor="">
                  Address <span className="important_field">*</span>
                </label>
                <input
                  className="form__input"
                  id="Address"
                  type="text"
                  placeholder="Address"
                  name="address"
                  onChange={(e) => handleEditChange(e, index)}
                  value={student.address}
                />

                <div className="error-message" style={{ color: "red" }}>
                  {errors.address}
                </div>
              </div>
              <div className="form__card">
                <div className="form__row">
                  <div className="form__header form__header--justifynone">
                    <h4
                      className="title title--h4"
                      style={{ background: "none" }}
                    >
                      Picking Up -
                    </h4>

                    <div className="btnActions">
                      <button
                        className="btn btn--primary"
                        type="button"
                        onClick={(e) => {
                          addAttendingWith(e, index);
                        }}
                      >
                        + Add Another Person
                      </button>
                    </div>
                  </div>

                  {student?.school_attending_withs?.map((attending, i) => (
                    <div className="form__group form__group--flex" key={index}>
                      <div className="form__group__col">
                        <label htmlFor="">
                          Select Relations{" "}
                          <span className="important_field">*</span>
                        </label>
                        <select
                          id={`Pattending-${index}`}
                          className="form__select"
                          name={`attending_with`}
                          value={attending.attending_with}
                          onChange={(e) => handleArrayChange(e, index, i)}
                        >
                          <option value="Caregiver">Care Giver</option>
                          <option value="Parent">Parent</option>
                        </select>
                      </div>

                      <div className="form__group__col">
                        <label htmlFor="">
                          Name <span className="important_field">*</span>
                        </label>
                        <input
                          className="form__input"
                          id={`Pname-${index}`}
                          type="text"
                          placeholder="Name (must be different than emergency contact) *"
                          name={`name`}
                          value={attending.name}
                          onChange={(e) => handleArrayChange(e, index, i)}
                        />
                        <div className="error-message" style={{ color: "red" }}>
                          {errors?.school_attending_withs[index]?.name}
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        State <span className="important_field">*</span>
                      </label>
                      <select
                        name="state"
                        className="form__select"
                        value={student.state}
                        onChange={(e) => handleEditChange(e, index)}
                        style={{ width: "100%", height: "50px" }}
                      >
                        <option value="" selected disabled>
                          Select State
                        </option>
                        {getAllStateApi &&
                          getAllStateApi.map((state, index) => (
                            <option key={index} value={state?.name}>
                              {state?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Zip <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pzip"
                        type="number"
                        placeholder="Zip *"
                        name="zipcode"
                        value={student.zipcode}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.zipcode}
                      </div>
                    </div>
                  </div>

                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        Doctor Name <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pdoctorname"
                        type="text"
                        placeholder="Doctor’s Name *"
                        name="doctor_name"
                        value={student.doctor_name}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.doctor_name}
                      </div>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Doctor Phone <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pdoctorphone"
                        type="text"
                        maxlength={14}
                        placeholder="Doctor’s Phone *"
                        name="doctor_phone"
                        value={student.doctor_phone}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.doctor_phone}
                      </div>
                    </div>
                  </div>

                  <div className="form__group form__group--flex">
                    <div className="form__group__col">
                      <label htmlFor="">
                        Emergency Contact's Name{" "}
                        <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pecontact"
                        type="text"
                        placeholder="Emergency Contact's Name *"
                        name="emergency_name"
                        value={student?.emergency_name}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.emergency_name}
                      </div>
                    </div>
                    <div className="form__group__col">
                      <label htmlFor="">
                        Emergency Contact's Phone{" "}
                        <span className="important_field">*</span>
                      </label>
                      <input
                        className="form__input"
                        id="Pephone"
                        type="text"
                        maxlength={16}
                        pattern="[0-9 ()-]*"
                        placeholder="Emergency Contact's Phone *"
                        name="emergency_phone"
                        value={student?.emergency_phone}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <div className="error-message" style={{ color: "red" }}>
                        {errors.emergency_phone}
                      </div>
                    </div>
                  </div>

                  <div className="form__group">
                    <h4
                      className="title title--h4"
                      style={{ background: "none" }}
                    >
                      Agree to Photo Release * (View Policy)
                    </h4>
                  </div>

                  <div className="form__radio">
                    <div className="form__radio__option">
                      <input
                        className="form__radio__input"
                        type="radio"
                        id="yes"
                        name="photo_release"
                        value="Yes"
                        checked={student.photo_release === true}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <label htmlFor="yes" className="form__radio__label">
                        Yes
                      </label>
                    </div>
                    <div className="form__radio__option">
                      <input
                        className="form__radio__input"
                        type="radio"
                        id="No"
                        value="No"
                        name="photo_release"
                        checked={student.photo_release === false}
                        onChange={(e) => handleEditChange(e, index)}
                      />
                      <label htmlFor="no" className="form__radio__label">
                        No
                      </label>
                    </div>
                    <div className="error-message" style={{ color: "red" }}>
                      {errors.photo_release}
                    </div>
                  </div>

                  <div className="form__group">
                    <label htmlFor="">
                      Parent Signature<span className="important_field">*</span>
                    </label>
                    <textarea
                      id="ElectronicSignature"
                      className="form__textarea"
                      placeholder="Electronic Signature *"
                      name="parent_signature"
                      value={student.parent_signature}
                      onChange={(e) => handleEditChange(e, index)}
                    ></textarea>
                    <div className="error-message" style={{ color: "red" }}>
                      {errors.parent_signature}
                    </div>
                  </div>
                </div>
                <div
                  className="form__group form__group--right"
                  style={{ marginTop: "3%" }}
                >
                  <button className="btn btn--primary" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
      })}
    </>
  );
}

export default StudentUpdate;
