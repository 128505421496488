import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close-icon.svg";
import {
  convertDateFormat,
  customStyles,
  dateFormatter,
  timeFormatter,
} from "../../adminUtility";
import Select from "react-select";
import { getClassForDropdown } from "../../adminApi/addClassList";
import { getMakeUpModalkDetailsAPI } from "../../adminApi/makeUpClassApi";
import { getClassesForMakeUp } from "../../adminApi/classes";
import DatePicker from "react-datepicker";
import { ReactComponent as SortIcon } from "../../assets/images/icons/sort.svg";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { editMakeup } from "../../adminApi/clients";
import LoaderComponent from "../LoaderComponent";
const convertDateToMMDDYYYY = (dateStr) => {
  const date = new Date(dateStr);
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
};

function EditMakeupClass({
  editMakeupClass,
  openEditMakeupClass,
  setEditMakeupClass,
  getMakeupClassData,
}) {
  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const [makeUpData, setMakeUpData] = useState();
  const [makeupApiData, setMakeupApiData] = useState(makeUpData);
  const [makeupForm, setMakeUpFormData] = useState({
    selectedDate: formatDate(new Date()),
    selectedClass: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState("5");
  const [classListData, setClassList] = useState();
  const [selectedClassID, setSelectedClassId] = useState();
  const [sortKey, setSortKey] = useState({
    sort: "0",
    isAsc: true,
  });
  const [selectedClass, setSelectedClass] = useState(null);
  const [currentSelections, setCurrentSelections] = useState({});
  const [memos, setMemos] = useState({});
  const handleRowChange = (e) => {
    setSelectedRow(e.target.value);
  };
  const [allDetails, setAllDetails] = useState([]);

  const handleDateChange = (date) => {
    if (selectedClass !== null) {
      setSelectedClass(null);
    }
    setMakeUpFormData({ ...makeupForm, selectedDate: formatDate(date) });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getClassesForMakeUpAPI = async () => {
    setLoading(true);
    try {
      const response = await getClassesForMakeUp(
        makeupForm?.selectedDate,
        sortKey,
        token,
        currentPage,
        selectedRow,
        selectedClass
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setMakeupApiData(response?.data.getClassesForMakeUp);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchHandler = async () => {
    getClassesForMakeUpAPI();
  };

  useEffect(() => {
    getClassesForMakeUpAPI();
  }, [
    makeupForm?.selectedDate,
    sortKey,
    currentPage,
    selectedRow,
    selectedClass?.label,
  ]);

  useEffect(() => {
    const data = {
      date: makeupForm?.selectedDate,
      classId: selectedClassID,
    };

    const classSearched = async () => {
      setLoading(true);
      try {
        const response = await getMakeUpModalkDetailsAPI(data, token);
        if (response?.data?.length) {
          setLoading(false);
        } else {
          setLoading(false);
        }
        setMakeUpData(response?.data);
      } catch (error) {
        setLoading(false);
      }
    };
    if (selectedClassID !== undefined) {
      classSearched();
    }
  }, [selectedClassID]);

  // CLASS SEARCHING
  useEffect(() => {
    const fetchSortedClass = async () => {
      const token = localStorage.getItem("access_token");
      try {
        setLoading(true);
        const response = await getClassForDropdown(token, 0, "2");

        setClassList(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchSortedClass();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedClass(selectedOption);
  };

  const classOptions = [
    { value: "", label: "Select Class" },
    ...(Array.isArray(classListData)
      ? classListData
          .filter((val) => val?.name.trim() !== "")
          .map((val) => ({
            show: val?.name,
            value: val?.name,
            label: `${val?.name} ${
              val?.schedules?.length > 0 ? ", (" : ""
            }${val?.schedules
              ?.map((el) => {
                if (el?.weekday && el?.start_time && el?.end_time) {
                  return `${el.weekday.substring(0, 3)} ${timeFormatter(
                    el.start_time
                  )} - ${timeFormatter(el.end_time)}`;
                }
                return null;
              })
              .filter(Boolean)
              .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
          }))
      : []),
  ];
  useEffect(() => {
    if (allDetails?.length > 0 && Object.keys(currentSelections).length === 0) {
      // Reset state only if allDetails is populated and currentSelections is empty
      setAllDetails([]);
      setCurrentSelections({});
      setMemos({});
    }
  }, [allDetails, currentSelections]);
  // Handle makeup option selection
  const handleMakeupOptionSelect = (index, selection, memo) => {
    const { date, classScheduleDetailId, option } = selection;

    const newEntry = {
      date,
      classScheduleDetailId,
      option,
      memo,
    };

    // Clear all details and add the new entry
    setAllDetails([newEntry]);

    // Clear previous selections and set the current one
    setCurrentSelections({
      [index]: option,
    });

    // Update memos
    setMemos({
      [index]: memo,
    });
  };

  useEffect(() => {
    if (editMakeupClass?.value) {
      const { date, memo, type } = editMakeupClass.value;
      const formattedDate = convertDateToMMDDYYYY(date);
      const matchingIndex = makeUpData?.findIndex(
        (item) => item?.date === formattedDate
      );

      if (matchingIndex !== -1) {
        setMemos({
          [matchingIndex]: memo,
        });

        let option = "";

        switch (type.toLowerCase()) {
          case "make up":
            option = "makeup";
            break;
          case "trial":
            option = "trial";
            break;
          case "drop in":
            option = "dropIn";
            break;
          default:
            option = "";
        }

        setCurrentSelections({
          [matchingIndex]: option,
        });
      }
    }
  }, [editMakeupClass, makeUpData]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!allDetails || !allDetails[0]) {
      toast.error("No makeup details found.", {
        toastId: "error",
      });
      return;
    }

    const makeupStatus =
      allDetails[0]?.option === "makeup"
        ? "M"
        : allDetails[0]?.option === "trial"
        ? "T"
        : allDetails[0]?.option === "dropIn"
        ? "D"
        : "";

    const payload = {
      date: convertDateFormat(allDetails[0]?.date) || "",
      classScheduleDetailId: allDetails[0]?.classScheduleDetailId || "",
      type: makeupStatus,
      memo: allDetails[0]?.memo || "",
    };

    setLoading(true);
    try {
      const response = await editMakeup(token, payload, editMakeupClass?.value);
      if (response?.response?.data?.data !== null) {
        setLoading(false);
        toast.success(response?.data);
        setEditMakeupClass(false);
        getMakeupClassData();
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (openEditMakeupClass?.value) {
      setSelectedClassId(openEditMakeupClass.value.classId);
      setAllDetails([]);
      setCurrentSelections({});
      setMemos({});
    }
  }, [openEditMakeupClass]);

  useEffect(() => {
    if (Object.keys(currentSelections).length > 1) {
      setCurrentSelections({});
      setAllDetails([]);
      setMemos({});
    }
  }, [currentSelections]);

  useEffect(() => {
    // Force re-render when currentSelections changes
    setCurrentSelections((prev) => ({ ...prev }));
  }, [currentSelections]);
  // prefilled .

  return (
    <div
      className={`Admin-modal ${
        openEditMakeupClass ? "Admin-modal--open" : ""
      }`}
      id="modalAddNewClass"
      role="dialog"
    >
      {loading && <LoaderComponent />}
      <div class="Admin-modal__dialog Admin-modal__dialog--lg">
        <div
          class="Admin-modal__content"
          style={{
            margin: "0px 10px",
            width: "95vw",
            height: "90vh",
            minHeight: "90vh",
          }}
        >
          <div class="Admin-modal__head" style={{ borderRadius: "10px" }}>
            <h2 class="Admin-modal__head--title">+ Edit Makeup Slots</h2>
            <button
              class="Admin-modal__close"
              onClick={() => {
                setEditMakeupClass(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>

          <div class="Admin-modal__body makeupBody" style={{ height: "100%" }}>
            <div class="Admin-modal__bodytab ">
              <div
                className=""
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div className="column">
                  <label htmlFor="">Select Date(MM/DD/YYYY)</label>

                  <DatePicker
                    name="selectedDate"
                    className="adminform__input"
                    selected={new Date(makeupForm?.selectedDate)}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Makeup Date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>

                <div className="column" style={{ maxWidth: "300px" }}>
                  <label htmlFor="">Select Class</label>

                  <Select
                    styles={customStyles}
                    value={selectedClass}
                    onChange={handleChange}
                    options={classOptions}
                  />
                </div>
                <div className="" style={{ marginTop: "20px" }}>
                  <button
                    className="adminbtn adminbtn-primary"
                    onClick={searchHandler}
                  >
                    Search
                  </button>
                </div>
                {/* </>
                  )} */}
              </div>

              {makeupApiData?.data?.length > 0 && (
                <p className="label" style={{ color: "red" }}>
                  *Select Class For Makeup{" "}
                </p>
              )}
              {/* TABLE  */}

              <div className="flexbox">
                {/* SEARCHED BY DATE  */}
                {makeupApiData?.data?.length > 0 && (
                  <div
                    className="column"
                    style={{ height: "50vh", overflowY: "scroll" }}
                  >
                    <div
                      className="Table"
                      style={{ minHeight: "40vh", overflowY: "scroll" }}
                    >
                      <div className="Heading">
                        <div
                          className="Cell  Heading_style"
                          style={{ width: "65px" }}
                          onClick={() => {
                            setSortKey((prevSortKey) => ({
                              sort: 0,
                              isAsc: !prevSortKey.isAsc,
                            }));
                          }}
                        >
                          <p>
                            {" "}
                            <SortIcon /> &nbsp; ID
                          </p>
                        </div>
                        <div
                          className="Cell  Heading_style"
                          style={{ width: "180px" }}
                          onClick={() => {
                            setSortKey((prevSortKey) => ({
                              sort: 1,
                              isAsc: !prevSortKey.isAsc,
                            }));
                          }}
                        >
                          <p>
                            {" "}
                            <SortIcon /> &nbsp;Class Name
                          </p>
                        </div>
                        <div
                          className="Cell  Heading_style"
                          style={{ width: "120px" }}
                        >
                          <p>&nbsp;Teacher</p>
                        </div>
                        <div
                          style={{ width: "120px" }}
                          className="Cell  Heading_style"
                        >
                          <p>&nbsp;Schedule</p>
                        </div>
                        <div
                          className="Cell  Heading_style"
                          onClick={() => {
                            setSortKey((prevSortKey) => ({
                              sort: 4,
                              isAsc: !prevSortKey.isAsc,
                            }));
                          }}
                        >
                          <p>
                            {" "}
                            <SortIcon /> &nbsp;Min Age
                          </p>
                        </div>
                        <div
                          className="Cell  Heading_style"
                          onClick={() => {
                            setSortKey((prevSortKey) => ({
                              sort: 5,
                              isAsc: !prevSortKey.isAsc,
                            }));
                          }}
                        >
                          <p>
                            {" "}
                            <SortIcon /> &nbsp;Max Age
                          </p>
                        </div>
                      </div>

                      {makeupApiData?.data?.map((val, index) => {
                        return (
                          <div
                            key={val.id}
                            className="Row"
                            style={{
                              cursor: "pointer",
                              background:
                                val?.id == selectedClassID
                                  ? "#5497C7"
                                  : index % 2 === 0
                                  ? "rgba(134, 134, 134, 0.1490196078)"
                                  : "white",
                              color:
                                val?.id == selectedClassID ? "white" : "black",
                              border: "1px solid black",
                            }}
                            onClick={() => {
                              setSelectedClassId(val.id);
                            }}
                          >
                            <div className="Cell">
                              <p>{val?.id}</p>
                            </div>
                            <div className="Cell">
                              <p>{val?.name ? val?.name : "-"}</p>
                              <p>
                                {dateFormatter(val?.start_date)} <br />
                                to <br />
                                {dateFormatter(val?.end_date)}
                              </p>
                            </div>
                            <div className="Cell">
                              {val?.schedules?.map((teacher) => {
                                return (
                                  <p>
                                    {teacher?.name !== null
                                      ? teacher?.name
                                      : "-"}
                                  </p>
                                );
                              })}
                            </div>
                            <div className="Cell">
                              {val?.schedules?.map((teacher) => {
                                return (
                                  <p>
                                    {teacher?.weekday !== null
                                      ? teacher?.weekday
                                      : "-"}{" "}
                                    <br />
                                    {timeFormatter(teacher?.start_time)} -{" "}
                                    {timeFormatter(teacher?.end_time)}
                                  </p>
                                );
                              })}
                            </div>
                            <div className="Cell">
                              <p>{val?.min_age}</p>
                            </div>
                            <div className="Cell">
                              <p>{val?.max_age}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div
                      className="flexbox"
                      style={{ alignItems: "center ", width: "100%" }}
                    >
                      <div className="column" style={{ width: "60%" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={makeupApiData?.totalPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                        />
                      </div>

                      <div className="column" style={{ width: "10%" }}>
                        <label htmlFor="" className="label">
                          Number of Rows to Show
                        </label>
                        <select
                          style={{ width: "100px" }}
                          name="limit"
                          id="limit"
                          className="adminform__select"
                          value={selectedRow}
                          onChange={handleRowChange}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {selectedClass !== undefined &&
                  makeupApiData?.data?.length == 0 && (
                    <div className="column">
                      <p
                        style={{
                          color: "red",
                          marginTop: "5%",
                          marginLeft: "10%",
                        }}
                      >
                        No Data Found
                      </p>
                    </div>
                  )}

                {/* FINAL SEARCH TABLE DATA  */}
                <div className="column">
                  {makeUpData?.length > 0 ? (
                    <div
                      className="adminform__group"
                      style={{ height: "49vh", overflowY: "scroll" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ color: "white", width: "70%" }}>
                              Class Date
                            </th>
                            <th style={{ color: "white", width: "30%" }}>
                              Memo
                            </th>
                            <th style={{ color: "white", width: "30%" }}>
                              Makeup Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {makeUpData?.map((val, index) => (
                            <tr key={index}>
                              <td style={{ maxWidth: "17%", minWidth: "15%" }}>
                                {val?.date}
                                <br />
                                {val?.slotsLeft === null || val?.slotsLeft < 0
                                  ? "(0 Slots Available)"
                                  : `(${val?.slotsLeft} seats available)`}
                              </td>
                              <td>
                                <input
                                  style={{
                                    maxWidth: "80px",
                                    minWidth: "120px",
                                  }}
                                  className="adminform__input"
                                  type="text"
                                  placeholder="Memo"
                                  value={memos[index] || ""}
                                  onChange={(e) => {
                                    setMemos((prev) => ({
                                      ...prev,
                                      [index]: e.target.value,
                                    }));
                                    handleMakeupOptionSelect(
                                      index,
                                      {
                                        date: convertDateToMMDDYYYY(val?.date),
                                        classScheduleDetailId:
                                          val?.classScheduleDetailId,
                                        option: currentSelections[index],
                                      },
                                      e.target.value
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <select
                                  name=""
                                  id=""
                                  className="adminform__select"
                                  onChange={(e) =>
                                    handleMakeupOptionSelect(
                                      index,
                                      {
                                        date: convertDateToMMDDYYYY(val?.date),
                                        classScheduleDetailId:
                                          val?.classScheduleDetailId,
                                        option: e.target.value,
                                      },
                                      memos[index]
                                    )
                                  }
                                  value={currentSelections[index] || ""}
                                >
                                  <option value="">
                                    Select MakeUp Options
                                  </option>
                                  <option value="makeup">Makeup</option>
                                  <option value="trial">Trial</option>
                                  <option value="dropIn">DropIn</option>
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center", margin: "7% 0%" }}>
                      {" "}
                      <h3 className="title--h4">No Data Found!</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div class="Admin-modal__footer">
              <div
                class="admin-button__group"
                style={{
                  display: "flex",
                  alignItems: "right",
                  marginLeft: "75%",
                  gap: "20px",
                }}
              >
                <button
                  class="adminbtn adminbtn-blueOutline"
                  onClick={() => {
                    setEditMakeupClass(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  class="adminbtn adminbtn-primary"
                  onClick={(e) => {
                    handleEditSubmit(e);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMakeupClass;
