import axios from "axios";
import {
  GET_AGE_CLASSES,
  GET_AGE_CLASSES_NEW,
  GET_CLASS_FOR_MAKEUP,
} from "../pages/adminQueries/Classes";
import { openSessionModal } from "../../reducers/sessionHandler";
import store from "../../store/store";

export async function getClassesForAge(token, classId, ageRangeData) {
  const id = parseInt(classId);
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_AGE_CLASSES,
        variables: {
          ageRangeId: id,
          page: 0,
          limit: 100,
          // className: "",
          ageRange: ageRangeData,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClassesCMSForBrowseByAge?.data;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getClassesForAgeMew(token, classId, className, ageRange) {
  const id = parseInt(classId);

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_AGE_CLASSES_NEW,
        variables: {
          ageRangeId: id,
          page: 0,
          limit: 100,
          className: className ? className : "",
          ageRange: ageRange,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClassesCMSAdminBrowseByAge?.data;
  } catch (error) { }
}

const dateFormatterYYMMDD = (dateString) => {
  const parts = dateString.split("/");
  if (parts.length === 3) {
    const [month, day, year] = parts;
    return `${year}/${month}/${day}`;
  } else {
    console.error("Invalid date format");
    return null;
  }
};

export const getClassesForMakeUp = async (
  date,
  sortKey,
  token,
  currentPage,
  selectedRow,
  selectedClass
) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASS_FOR_MAKEUP,
        variables: {
          className:
            selectedClass?.label === "Select Class"
              ? " "
              : selectedClass?.show,
          date: dateFormatterYYMMDD(date),
          pageInfo: {
            limit: parseInt(selectedRow),
            page: currentPage,
            isAsc: sortKey?.isAsc,
            sort: sortKey?.sort.toString(),
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) { }
};
