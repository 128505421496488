import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import aboutBanner from "../../../parent-assets/images/home-banner.jpg";
import warriorProgram from "../../../parent-assets/images/New program Images/warrior.png";
import teamProgram from "../../../parent-assets/images/New program Images/Group 237884.png";
import steamProgram from "../../../parent-assets/images/New program Images/steam.png";
import gymnasticProgram from "../../../parent-assets/images/New program Images/Group 237885.png";
import cheerProgram from "../../../parent-assets/images/New program Images/Group 237883.png";
import artProgram from "../../../parent-assets/images/New program Images/Group 237886.png";
import musicProgram from "../../../parent-assets/images/New program Images/Group 237887.png";
import cookingProgram from "../../../parent-assets/images/New program Images/Group 237888.png";
import danceProgram from "../../../parent-assets/images/New program Images/dance-program.png";
import yogaProgram from "../../../parent-assets/images/New program Images/yoga-program.png";
import campProgram from "../../../parent-assets/images/New program Images/camp-program.png";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer.jsx";
// New Enrichment
import enrichmentProgram from "../../../parent-assets/images/New program Images/enrichment-combo.png";
import { getCampProgramApi } from "../../../api/classApi";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../reducers/loadingSlice";
import { getAgeProgramHeaderImage } from "../../../../Admin/adminApi/ageProgramHeaderApi";
import { getCmsPrograms } from "../../../../Admin/adminApi/program.js";

function BrowseByProgramOptions() {
  const token = localStorage.getItem("access_token");
  const [headerImage, setHeaderImage] = useState();
  const [description, setDescription] = useState();
  const [showShimmer, setShowShimmer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [programApiData, setProgramApiData] = useState();

  useEffect(() => {
    getHeadersImage();
  }, []);

  const getHeadersImage = async () => {
    try {
      setShowShimmer(true);
      const response = await getAgeProgramHeaderImage(token);
      setHeaderImage(response?.data?.data?.[0]?.browse_by_program_header);
      setDescription(response?.data?.data?.[0]?.browse_by_program_desc);
      setShowShimmer(false);
    } catch (error) {
      setShowShimmer(false);
      return error;
    }
  };

  const campProgramApi = async (type) => {
    if (type === "camp") {
      dispatch(setLoading(true));
      try {
        const response = await getCampProgramApi("Camp");
        if (response && response.data) {
          setTimeout(() => {
            dispatch(setLoading(false));
          }, [1000]);
          navigate(`/camp/175`, {
            state: {
              data: response?.data,
            },
          });
        }
      } catch (error) {
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setLoading(false));
      navigate("/browse-by-programs/Warriors/181?browse-by=true");
    }
  };

  const programs = [
    {
      name: "Gymnastics",
      link: "/browse-by-programs/Gymnastics/167?browse-by=true",
      imgSrc: gymnasticProgram,
    },
    {
      name: "Teams",
      link: "/cheer/team-gymnastic/6203",
      imgSrc: teamProgram,
    },
    {
      name: "Cheer",
      link: "/browse-by-programs/Cheer/169?browse-by=true",
      imgSrc: cheerProgram,
    },
    {
      name: "Enrichment Combos",
      link: "/enrichment/179",
      imgSrc: enrichmentProgram,
    },
    {
      name: "Art",
      link: "/browse-by-programs/Art/170?browse-by=true",
      imgSrc: artProgram,
    },
    {
      name: "Music",
      link: "/browse-by-programs/Music/172?browse-by=true",
      imgSrc: musicProgram,
    },
    {
      name: "Cooking",
      link: "/browse-by-programs/Cooking/171?browse-by=true",
      imgSrc: cookingProgram,
    },
    {
      name: "Steam",
      link: "/browse-by-programs/Steam/173?browse-by=true",
      imgSrc: steamProgram,
    },
    {
      name: "Dance",
      link: "/browse-by-programs/Dance/177?browse-by=true",
      imgSrc: danceProgram,
    },
    {
      name: "Yoga",
      link: "/browse-by-programs/Yoga/178?browse-by=true",
      imgSrc: yogaProgram,
    },
    {
      name: "Camp",
      onClick: () => campProgramApi("camp"),
      imgSrc: campProgram,
    },
    {
      name: "Warriors",
      onClick: () => campProgramApi("warrior"),
      imgSrc: warriorProgram,
    },
  ];

  // Get All Program Data
  useEffect(() => {
    const getAllProgramCMS = async () => {
      try {
        const response = await getCmsPrograms(token);
        setProgramApiData(response);
      } catch (error) {
      }
    };
    getAllProgramCMS();
  }, [token]);

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Birthday"} />
      ) : (
        <div className="contentwraper">
          <section className="siteBanner" style={{ background: "#00BBE3" }}>
            <div className="siteBanner__overlay">
              <img
                className="siteBanner__img"
                src={headerImage ? headerImage : aboutBanner}
                alt="about-us-banner"
              />
            </div>
          </section>

          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="facilitiesSec">
            <div className="container">
              <h1 className="title title--extrabold">Program Options</h1>
              <div className="shortText">
                <p>
                  {description
                    ? description
                    : `At Gymtime, our programs guide children on their path to excellence, whether they aspire to be gymnasts, artists,
                scientists, chefs, musicians or dancers. We prioritize
                exploration and creativity, nurturing not only their skills but
                also instilling important values such as teamwork, discipline,
                and self-expression, all in preparation for a bright and dynamic
                future.`}
                </p>
              </div>
              <div className="maxwith1000">
                <ul className="commonList">
                  {programs.map((program, index) => {
                    const apiProgram = programApiData?.find(
                      (apiProg) => apiProg.name === program.name
                    );
                    const isActiveTrue = apiProgram && !apiProgram.is_active;
                    // Render the program card only if is_active_true is not true
                    if (!isActiveTrue) {
                      return (
                        <li key={index} className="commonList__item">
                          {program.onClick ? (
                            <div
                              className="commonList__card"
                              onClick={program.onClick}
                            >
                              <img
                                className="commonList__card__img"
                                src={program.imgSrc}
                                alt=""
                              />
                            </div>
                          ) : (
                            <Link
                              className="commonList__card"
                              to={program.link}
                            >
                              <img
                                className="commonList__card__img"
                                src={program.imgSrc}
                                alt=""
                              />
                            </Link>
                          )}
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default BrowseByProgramOptions;
